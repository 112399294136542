.App {
  background-color:black;
  height: calc(100vh - 40px);
  display: flex;
  padding: 20px;
  font-size: calc(10px + 1vw);
  color: white;
}

/* Order */
.Side-menu {
  width: 100px;
  margin-right: 20px;
}

.Side-menu .MuiBox-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Ofo-logo {
  width: 100%;
  max-width: 50px;
  border-radius: 10%;
  vertical-align: middle;
}

.Circle {
  display: inline-block;
  border-radius: 50%;
  width: 100%;
  color: #fff;
}

.Date-input {
  display: inline-flex;
  width: 100%;
  max-width: 220px;
  align-items: center;
  border-top: 0px;
  border-bottom: 2px solid white;
}

.Date-input input {
  background: transparent;
  border: none;
  font-size: calc(10px + 1vw);
  color: white;
  height: 30px;
  width: calc(100% - 48px);
  max-width: 152px;
  text-align: center;
  padding: 5px;
}

.Date-input button {
  color: white;
}

.FontL {
  font-size: calc(12px + 1vw);
}

.FontM {
  font-size: calc(10px + 1vw);
}

.FontS {
  font-size: calc(7px + 1vw);
}